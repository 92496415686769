import getData from "./getData"
import {Review} from "../utils/types"
import createLoadMore from "./createLoadMore"
import appendReviewsToBody from "./appendReviewsToBody"
import loadMoreReviews from "./loadMoreReviews"

export default (id: number, displayedReviews: number, reviewsPerPage: number, section: HTMLElement, header: HTMLDivElement, body: HTMLDivElement) => {
    getData(id, displayedReviews, reviewsPerPage)
        .then(data => {
            if (!data.data.count) return

            const reviews: Review[] = data?.data?.items
            displayedReviews += reviewsPerPage

            const rating: number = parseFloat(data.data.rating.replace(',', '.'))
            const reviewsCount: number = data.data.reviews
            header.innerHTML = `
                       ${rating}/5 <span class="heading--4 section--reviews__number">(${reviewsCount} avis)</span>
                    `

            body.classList.add('reviews')
            body.innerHTML = ''
            appendReviewsToBody(reviews, body)

            loadMoreReviews(id, reviewsPerPage, createLoadMore(id,reviewsPerPage, section, body), body)
        })
}
