import {Review} from "../utils/types"

export default (reviews: Review[] | null, body: HTMLDivElement) => {
    reviews?.map((review) => {
        const rate = parseInt(review.note)
        const title = review.titre
        const desc = review.commentaire
        const name = review.anonyme
        const date = review.date

        const card = document.createElement('article')
        card.classList.add('review')
        card.innerHTML = `
                    <progress class="review__rating" value="${rate}" max="5"></progress>
                    <h1 class="heading--4 review__title">${title}</h1>
                    <p class="review__desc text--medium">${desc}</p>
                    <div class="review__footer text--small">
                        <span class="review__name">${name}</span>
                        <span class="review__date">${date}</span>
                    </div>
                 `
        body.appendChild(card)
    })
}
